<template>
  <div>
    <page-toolbar title="Edit Contact">
      <template slot="action">
        <v-btn text @click="saveData()" :disabled="!valid">Save</v-btn>
      </template>
    </page-toolbar>

    <v-container>
      <page-title title="Contact">
        <template slot="action">
          <v-btn class="mr-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn class="mr-1" @click="saveData()" color="primary">Save</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col>
          <v-form v-model="valid" ref="form">
            <v-card class="mb-4">
              <v-card-title>General</v-card-title>
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="contactData.no_" :error-messages="formErrors.no_" :rules="contactDataRules.no_" class="mb-4" label="No.:" required></v-text-field>
                    <v-select :items="type_" v-model="contactData.type_" :error-messages="formErrors.type_" label="Type:" class="mb-4" required></v-select>
                    <v-text-field v-model="contactData.first_name" :error-messages="formErrors.first_name" :rules="contactDataRules.first_name" class="mb-4" label="First Name:" required></v-text-field>
                    <v-text-field v-model="contactData.last_name" :error-messages="formErrors.last_name" class="mb-4" label="Last Name:" required></v-text-field>
                    <v-select :items="gender" v-model="contactData.gender" :error-messages="formErrors.gender" label="Gender:" required></v-select>
                    <v-text-field v-model="contactData.email" :error-messages="formErrors.email" class="mb-4" label="Email:"></v-text-field>
                    <v-text-field v-model="contactData.phone_no" :error-messages="formErrors.phone_no" class="mb-4" label="Phone No.:" required></v-text-field>
                    <v-text-field v-model="contactData.mobile_phone_no" :error-messages="formErrors.mobile_phone_no" class="mb-4" label="Mobile No.:" required></v-text-field>

                    <v-file-input @change="previewPicture" ref="refPicture" v-model="contactData.picture" :error-messages="formErrors.picture" class="mb-4" label="Picture"></v-file-input>

                    <v-img :src="resourceUrl(tmpPicture)" v-show="tmpPicture" height="64" contain></v-img>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="contactData.address" :error-messages="formErrors.address" class="mb-4" label="Address:"></v-text-field>
                    <v-text-field v-model="contactData.address2" :error-messages="formErrors.address2" class="mb-4" label="Address2:"></v-text-field>
                    <v-text-field v-model="contactData.city" :error-messages="formErrors.city" class="mb-4" label="City:"></v-text-field>
                    <v-text-field v-model="contactData.province" :error-messages="formErrors.province" class="mb-4" label="Provice:"></v-text-field>
                    <v-text-field v-model="contactData.country" :error-messages="formErrors.country" class="mb-4" label="Country:"></v-text-field>
                    <v-text-field v-model="contactData.post_code" :error-messages="formErrors.post_code" class="mb-4" label="Post Code:"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mb-4">
              <v-card-title>Company</v-card-title>
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="contactData.job_title" :error-messages="formErrors.job_title" class="mb-4" label="Job Title:"></v-text-field>
                    <v-text-field v-model="contactData.company_name" :error-messages="formErrors.company_name" class="mb-4" label="Company Name.:"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="contactData.office_no" :error-messages="formErrors.office_no" class="mb-4" label="Office No.:"></v-text-field>
                    <v-text-field v-model="contactData.fax_no" :error-messages="formErrors.fax_no" class="mb-4" label="Fax No.:"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
      </v-row>

      <v-row class="mb-2">
        <v-col>
          <div class="d-flex justify-end">
            <v-btn class="mr-1" @click="$router.go(-1)">Back</v-btn>
            <v-btn class="mr-1" @click="saveData()" color="primary">Save</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      panel: [1],
      addPermission: true,
      attrs: {
        boilerplate: true,
        elevation: 0,
      },
      selectedCampaign: null,
      benched: 0,

      valid: null,

      type_: ["Person", "Company"],
      gender: ["Male", "Female"],

      contactData: {
        no_: null,
        first_name: null,
        last_name: null,
        type_: null,
        gender: null,
        address: null,
        address2: null,
        city: null,
        province: null,
        country: null,
        post_code: null,
        email: null,
        phone_no: null,
        mobile_phone_no: null,
        office_no: null,
        company_name: null,
        job_title: null,
        fax_no: null,
        picture: null,
      },

      contactDataRules: {
        no_: [(v) => !!v || "No. is required"],
        first_name: [(v) => !!v || "First Name is required"],
        type_: [(v) => !!v || "Type is required"],
        gender: [(v) => !!v || "Gender is required"],
        // email: [
        //   (v) => !!v || "Email is required",
        //   (v) => /.+@.+/.test(v) || "E-mail must be valid",
        // ],
      },

      formErrors: {},

      tmpPicture: null,
    };
  },

  computed: {},

  methods: {
    previewPicture() {
      this.tmpPicture = "";
      this.tmpPicture = URL.createObjectURL(this.contactData.picture);
    },
    getDetail(id) {
      this.showLoadingOverlay(true);
      var params = {
        id: id,
      };
      this.$axios
        .get("contact/data-detail", {
          params: params,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.contactData = resData.data.contact;

            this.tmpPicture = resData.data.contact.picture;
            resData.data.contact.picture = null;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    saveData() {
      var _valid = this.$refs.form.validate();

      if (!_valid) return;

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.contactData.id);
      formData.append("no_", this.contactData.no_ || "");
      formData.append("type_", this.contactData.type_ || "");
      formData.append("gender", this.contactData.gender || "");
      formData.append("first_name", this.contactData.first_name || "");
      formData.append("last_name", this.contactData.last_name || "");
      formData.append("address", this.contactData.address || "");
      formData.append("address2", this.contactData.address2 || "");
      formData.append("city", this.contactData.city || "");
      formData.append("province", this.contactData.province || "");
      formData.append("country", this.contactData.country || "");
      formData.append("post_code", this.contactData.post_code || "");
      formData.append("email", this.contactData.email || "");
      formData.append("phone_no", this.contactData.phone_no || "");
      formData.append("mobile_phone_no", this.contactData.mobile_phone_no || "");
      formData.append("office_no", this.contactData.office_no || "");
      formData.append("company_name", this.contactData.company_name || "");
      formData.append("job_title", this.contactData.job_title || "");
      formData.append("fax_no", this.contactData.fax_no || "");
      if (this.contactData.picture != null)
        formData.append("picture", this.contactData.picture);
      this.$axios
        .post("contact/update", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.$awn.confirm(
              resData.message,
              () => {
                this.redirect("Sales.Contact");
              },
              false,
              {
                labels: {
                  confirm: "Success",
                },
              }
            );
          } else {
            this._valid = false;
            this.formErrors = resData.data.errors;
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.requiredLogin();
    this.modulePermission('contact', 'modify', true)

    this.getDetail(this.$route.query.id);
  },
};
</script>